import React from "react"
import { Helmet } from "react-helmet"
import classnames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import PlanScreenshot from "../images/marketing/plan.png"
import PlanEditorScreenshot from "../images/marketing/planEditor.png"
import BusinessModelCanvasScreenshot from "../images/marketing/businessModelCanvas.png"
import OrganizationsScreenshot from "../images/marketing/launchplanOrganizations.png"
import FinancialsScreenshot from "../images/marketing/financials.png"
import ExportScreenshot from "../images/marketing/export.png"
import PublicLayout from "../components/layouts/PublicLayout"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import AppButton from "../components/button"

const styles = theme => ({
  root: {
    backgroundColor: "white",
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
  },
  reverse: {},
  first: {},
  textImageModule: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateAreas: `'text2' 'text'`,
    gridGap: "4rem",
    alignItems: "center",
    padding: "2rem 0",
    maxWidth: 500,
    margin: "0 auto",

    "&$reverse": {
      [theme.breakpoints.up("md")]: {
        gridTemplateAreas: `'text2 text'`,
      },
    },

    "&$first": {
      paddingTop: "1rem",
    },

    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr",
      gridTemplateAreas: `'text text2'`,
      gridGap: "7rem",
      maxWidth: 1000,
      padding: "6rem 2rem",
    },
  },
  text2: {
    gridArea: "text2",
    justifySelf: "end",
    maxWidth: 450,
    width: "100%",
  },
  text: {
    gridArea: "text",
    maxWidth: 500,
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  headline: {
    margin: "1rem 0",
  },
  marginTop: {
    marginTop: "1rem",
  },
  separator: {
    width: 60,
    height: 4,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    margin: "1.5rem 0",
  },
  featureImg: {
    display: "block",
    marginBottom: 0,
    width: '100%'
  },
})

const FeaturesPage = props => {
  const { classes } = props

  return (
    <PublicLayout
      headline={"Launch Your Ideas"}
      subheading={
        "Easily create an effective business plan with solid financial forecasts"
      }
      canonicalUrl="https://launchplan.com/features"
    >
      <Helmet>
        <title>Business Plan Software for Small Businesses | LaunchPlan</title>
        <meta
          name="description"
          content="View the features of LaunchPlan, business plan software with financial forecasting for small businesses, entrepreneurs, & startups. Try it risk-free for 60 days."
        />
        <meta
          property="og:title"
          content="Business Plan Software for Small Businesses | LaunchPlan"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://launchplan.com/features" />
        <meta
          property="og:image"
          content="https://launchplan.com/launchplan-logo-vertical.png"
        />

        {/* <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="206" />
          <meta property="og:image:height" content="112" /> */}
        <meta
          property="og:image:alt"
          content="The LaunchPlan logo, a rocket launching"
        />
      </Helmet>
      <section className={classes.root}>
        <div
          className={classnames(
            classes.textImageModule,
            classes.borderBottom,
            classes.reverse
          )}
        >
          <div className={classes.text}>
            <Typography variant="subtitle1" className={classes.title}>
              {"STEP-BY-STEP PLANNING"}
            </Typography>
            <Typography variant="h2" className={classes.headline}>
              {"Stress-free business plans"}
            </Typography>
            <div className={classes.separator}></div>
            <Typography className={classes.description}>
              {
                "Write a detailed business plan online with step-by-step instructions. Built for small businesses, entrepreneurs, and startups, LaunchPlan offers full definitions, examples, and tutorials in every section, from your executive summary to marketing strategy and financials."
              }
            </Typography>
          </div>
          <Paper>
            <a href={PlanScreenshot}>
              <img
                src={PlanScreenshot}
                alt="LaunchPlan small-business plan software showing company overview"
                className={classes.featureImg}
              />
            </a>
          </Paper>
        </div>
        <div className={classnames(classes.textImageModule)}>
          <div className={classes.text}>
            <Typography variant="subtitle1" className={classes.title}>
              {"Business Model Canvas"}
            </Typography>
            <Typography variant="h2" className={classes.headline}>
              {"A one-page plan to start quick"}
            </Typography>
            <div className={classes.separator}></div>
            <Typography className={classes.description}>
              {
                "Show potential investors your path to success quickly with outling the most important aspects of your business in a proven Business Model Canvas format."
              }
            </Typography>
          </div>
          <Paper>
            <a href={BusinessModelCanvasScreenshot}>
              <img
                src={BusinessModelCanvasScreenshot}
                alt="Business Model Canvas in LaunchPlan’s easy business model canvas planning tool for small businesses"
                className={classes.featureImg}
              />
            </a>
          </Paper>
        </div>
        <div
          className={classnames(classes.textImageModule, classes.borderBottom, classes.reverse)}
        >
          <div className={classes.text}>
            <Typography variant="subtitle1" className={classes.title}>
              {"UNLIMITED COLLABORATION"}
            </Typography>
            <Typography variant="h2" className={classes.headline}>
              {"Build a plan together online"}
            </Typography>
            <div className={classes.separator}></div>
            <Typography className={classes.description}>
              {
                "Other business plan software providers charge for additional users. We don't. LaunchPlan allows unlimited team members to build a plan together online for free. Divide and conquer with members working on different sections at the same time."
              }
            </Typography>
          </div>
          <Paper>
            <a href={PlanEditorScreenshot}>
              <img
                src={PlanEditorScreenshot}
                alt="Mission statement in LaunchPlan business plan creator for startups and entrepreneurs"
                className={classes.featureImg}
              />
            </a>
          </Paper>
        </div>
        <div
          className={classnames(
            classes.textImageModule,
            classes.borderBottom
          )}
        >
          <div className={classes.text}>
            <Typography variant="subtitle1" className={classes.title}>
              {"Automated Financial Forecasting"}
            </Typography>
            <Typography variant="h2" className={classes.headline}>
              {"Ditch the spreadsheets"}
            </Typography>
            <div className={classes.separator}></div>
            <Typography className={classes.description}>
              {
                "LaunchPlan’s financial forecasting software helps small businesses automate their projections into clean charts and graphs. It’s easy to create your profit & loss (income statement), balance sheet, and cash flow statement. Think TurboTax for financial modeling."
              }
            </Typography>
          </div>
          <Paper>
            <a href={FinancialsScreenshot}>
              <img
                src={FinancialsScreenshot}
                alt="Budgeting and business projections in LaunchPlan financial forecasting software for small businesses"
                className={classes.featureImg}
              />
            </a>
          </Paper>
        </div>
        <div className={classnames(classes.textImageModule, classes.reverse)}>
          <div className={classes.text}>
            <Typography variant="subtitle1" className={classes.title}>
              {"Investor-Ready Plans"}
            </Typography>
            <Typography variant="h2" className={classes.headline}>
              {"Details that secure funding "}
            </Typography>
            <div className={classes.separator}></div>
            <Typography className={classes.description}>
              {
                "Show potential investors your path to success in a professional layout with modern visuals. LaunchPlan creates an impressive business plan to export or share online. Feel confident that your plan will be well organized and look great."
              }
            </Typography>
          </div>
          <Paper>
            <a href={ExportScreenshot}>
              <img
                src={ExportScreenshot}
                alt="Company description in LaunchPlan’s easy business planning tool for small businesses"
                className={classes.featureImg}
              />
            </a>
          </Paper>
        </div>
        <div className={classnames(classes.textImageModule)}>
          <div className={classes.text}>
            <Typography variant="subtitle1" className={classes.title}>
              {"Organization level features"}
            </Typography>
            <Typography variant="h2" className={classes.headline}>
              {"Organize and collaborate securely with your clients"}
            </Typography>
            <div className={classes.separator}></div>
            <Typography className={classes.description}>
              {
                "Manage LaunchPlan access and work with your clients on building and improving their business plans. Contact us today to join us as an organizational partner or to receive a demo of this feature set."
              }
            </Typography>
            <AppButton
              variant="primary"
              href="/contact-us"
              className={classes.marginTop}
              onClick={() =>
                trackCustomEvent({
                  category: "Features",
                  action: "Click",
                  label: "Organization Contact Us",
                })
              }
            >
              {"Contact Us"}
            </AppButton>
          </div>
          <Paper>
            <a href={OrganizationsScreenshot}>
              <img
                src={OrganizationsScreenshot}
                alt="LaunchPlan’s organization feature for managing access to easy business planning tool for your clients"
                className={classes.featureImg}
              />
            </a>
          </Paper>
        </div>
      </section>
    </PublicLayout>
  )
}

export default withStyles(styles)(FeaturesPage)
